import React, {Fragment, PropsWithChildren} from "react";
import {scroller} from "react-scroll";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Title} from "./Title";

export type TabsVariant = "contained" | "line";

export interface ITabItem<ValueType> {
    content?: React.ReactNode;
    scrollRef?: React.RefObject<HTMLElement>;
    scrollOffset?: number;
    scrollId?: string;
    title: React.ReactNode;
    value: ValueType;
    gtmClick?: string;
}

export type TabsAllowedValueTypes = number | string | undefined;

export type ITabsCoreProps<ValueType extends TabsAllowedValueTypes> = {
    fullWidth?: boolean;
    fullWidthBorder?: boolean;
    colorsSchema: {
        activeBackgroundColor: string;
        activeColor: string;
        inactiveBackgroundColor: string;
        inactiveColor: string;
    };
    onChange?: (value: ValueType) => void;
    tabs: ITabItem<ValueType>[];
    value?: ValueType;
    variant: TabsVariant;
    className?: string;
    titlesMaxWidth?: string;
};

export const TabsCore = <ValueType extends TabsAllowedValueTypes>(props: PropsWithChildren<ITabsCoreProps<ValueType>>) => {
    const onClick = (value: ValueType, scrollRef?: React.RefObject<HTMLElement>, scrollId?: string, scrollOffset?: number) => {
        props.onChange?.(value);

        if (scrollId && scrollOffset) {
            scroller.scrollTo(scrollId, {offset: scrollOffset, smooth: true});
        }

        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
        }
    };

    return (
        <TabsWrapper
            fullWidth={props.fullWidth}
            fullWidthBorder={props.fullWidthBorder}
            inactiveBackgroundColor={props.colorsSchema.inactiveBackgroundColor}
            className={props.className}
        >
            <div css={titlesWrapStyle(props.titlesMaxWidth)}>
                <Titles fullWidth={props.fullWidth} variant={props.variant}>
                    {props.tabs.map((tab) => {
                        const active = typeof props.value !== "undefined" ? props.value === tab.value : false;

                        return (
                            <Title
                                key={tab.value}
                                active={active}
                                colorsSchema={props.colorsSchema}
                                fullWidth={props.fullWidth}
                                onClick={() => onClick(tab.value, tab.scrollRef, tab.scrollId, tab.scrollOffset)}
                                variant={props.variant}
                                data-gtm-click={tab.gtmClick}
                                data-name="tab-title"
                                singleTab={props.tabs.length === 1}
                            >
                                {tab.title}
                            </Title>
                        );
                    })}
                </Titles>
            </div>

            {props.tabs.map((tab) => {
                return props.value === tab.value ? <Fragment key={`tc${tab.value}`}>{tab.content}</Fragment> : null;
            })}
        </TabsWrapper>
    );
};

const Titles = styled.div<{fullWidth?: boolean; variant: TabsVariant}>`
    display: inline-flex;
    border-radius: ${(props) => (props.variant === "line" ? 0 : "18rem")};
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

const titlesWrapStyle = (maxWidth?: string) =>
    maxWidth
        ? css`
              max-width: ${maxWidth};
          `
        : "";

const TabsWrapper = styled.div<{fullWidth?: boolean; fullWidthBorder?: boolean; inactiveBackgroundColor: string}>`
    width: ${({fullWidth}) => (fullWidth ? "100%" : "")};
    position: relative;

    ${({fullWidthBorder, inactiveBackgroundColor}) =>
        fullWidthBorder
            ? css`
                  &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 4px;
                      border-bottom: 4px solid ${inactiveBackgroundColor};
                      bottom: 0;
                      z-index: -1;
                  }
              `
            : ""};
`;
