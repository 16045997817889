import {useCallback} from "react";
import {useTheme} from "@emotion/react";

import {TabsColor} from "../components/Tabs";
import {ITabsCoreProps, TabsAllowedValueTypes, TabsVariant} from "../components/TabsCore";

interface IHook {
    getTabsColorsSchema: (color: TabsColor, variant: TabsVariant, activeColor?: TabsColor) => ITabsCoreProps<TabsAllowedValueTypes>["colorsSchema"];
}

export const useTabsColorsSchema = (): IHook => {
    const theme = useTheme();

    const getTabsColorsSchema = useCallback(
        (color: TabsColor, variant: TabsVariant, activeColor?: TabsColor): ITabsCoreProps<TabsAllowedValueTypes>["colorsSchema"] => {
            const activeColors = activeColor ? getTabsColorsSchema(activeColor, variant) : null;

            const isLineVariant = variant === "line";

            const colors = (() => {
                switch (color) {
                    case "dark": {
                        return {
                            activeBackgroundColor: theme.colors.primary,
                            activeColor: theme.colors.secondary,
                            inactiveBackgroundColor: theme.colors.secondary,
                            inactiveColor: isLineVariant ? theme.colors.secondary : "#fff"
                        };
                    }
                    case "full-dark": {
                        return {
                            activeBackgroundColor: theme.colors.secondary,
                            activeColor: theme.colors.secondary,
                            inactiveBackgroundColor: theme.colors.secondary,
                            inactiveColor: isLineVariant ? theme.colors.secondary : "#fff"
                        };
                    }
                    case "gray": {
                        return {
                            activeBackgroundColor: isLineVariant ? theme.colors.secondary : theme.colors.gray["300"],
                            activeColor: theme.colors.secondary,
                            inactiveBackgroundColor: theme.colors.gray["200"],
                            inactiveColor: theme.colors.gray["700"]
                        };
                    }
                    case "invert-dark": {
                        return {
                            activeBackgroundColor: theme.colors.primary,
                            activeColor: theme.colors.primary,
                            inactiveBackgroundColor: theme.colors.primary,
                            inactiveColor: theme.colors.primary
                        };
                    }
                    case "warm": {
                        return {
                            activeBackgroundColor: theme.colors.background["200"],
                            activeColor: theme.colors.secondary,
                            inactiveBackgroundColor: theme.colors.background["100"],
                            inactiveColor: isLineVariant ? theme.colors.gray["700"] : theme.colors.secondary
                        };
                    }
                    default:
                        throw new Error("Unknown TabsColor");
                }
            })();

            return activeColors ? {...colors, activeColor: activeColors.activeColor, activeBackgroundColor: activeColors.activeBackgroundColor} : colors;
        },
        [theme]
    );

    return {
        getTabsColorsSchema
    };
};
