import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {ITabsCoreProps, TabsAllowedValueTypes, TabsVariant} from "./TabsCore";

export const Title = styled.div<{
    active: boolean;
    fullWidth?: boolean;
    colorsSchema: ITabsCoreProps<TabsAllowedValueTypes>["colorsSchema"];
    variant: TabsVariant;
    singleTab: boolean; // Tabs component contains only one tab
}>`
    cursor: pointer;
    padding: 1.4rem 1.6rem;
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
    text-align: center;
    white-space: nowrap;

    ${(props) => {
        const transitionArgs = `${props.theme.transition.timingFunction} ${props.theme.transition.duration}`;
        return css`
            transition:
                background ${transitionArgs},
                color ${transitionArgs};
        `;
    }}

    ${(props) => {
        const color = props.active ? props.colorsSchema.activeColor : props.colorsSchema.inactiveColor;

        return css`
            color: ${color};

            &:hover {
                color: ${props.colorsSchema.activeColor};
            }

            svg {
                fill: ${color} !important;
            }
        `;
    }}

    ${(props) => {
        const background = props.active ? props.colorsSchema.activeBackgroundColor : props.colorsSchema.inactiveBackgroundColor;

        return props.variant === "line"
            ? css`
                  border-bottom: 4px solid ${background};
              `
            : css`
                  background-color: ${background};

                  &:hover {
                      background-color: ${props.colorsSchema.activeBackgroundColor};
                  }

                  &:first-of-type {
                      border-radius: ${props.singleTab ? "18rem" : "18rem 0 0 18rem"};
                  }

                  &:last-of-type {
                      border-radius: ${props.singleTab ? "18rem" : "0 18rem 18rem 0"};
                  }
              `;
    }}
`;
